import React, { FunctionComponent } from "react"
import { Link } from "gatsby"

import Button from "../components/Button"
import Layout from "../components/Layout"
import OnScreen from "../components/OnScreen"
import SEO from "../components/SEO"
import TypedText from "../components/TypedText"

interface Props {
  data: any
}

const Contact: FunctionComponent<Props> = ({ data }) => (
  <Layout>
    <SEO
      title="Contact/Book Will"
      description="Interested in booking Will to help you or your company gamify your momentum and develop success habits?"
      link="https://mooremomentum.com/contact/"
    />

    <div className="bg-blue mt-4">
      <div className="container mx-auto px-4">
        <h1 className="font-Orbitron py-4 text-2xl text-white sm:text-3xl lg:py-8 lg:text-4xl">
          Contact/Book Will
        </h1>
      </div>
    </div>

    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="mb-8 text-center">
        <h2 className="font-Orbitron mb-8 text-2xl md:text-3xl">
          Interested in booking Will to help you or your company gamify your
          momentum?
        </h2>

        <Button href="/speaking" link="GATSBY">
          Contact/Book Will
        </Button>
      </div>

      <OnScreen
        className="bg-blue p-4 rounded-md shadow-lg space-y-4 text-left text-white md:p-8"
        classToAdd="slide-in-left"
      >
        <p>
          <strong>
            Join The Moore Momentum Movement to Start Playing Today!
          </strong>
        </p>

        <p>
          Happiness = movement. But not just any movement - it must be small,
          consistent, purposeful movement in the right direction in order to
          compound into massive results.
        </p>

        <p>
          But taking the "right" short-term actions that will pay long-term
          dividends on our health, happiness, and success is boring and hard.
        </p>

        <p>
          My mission is to make it as fun and addicting as your favorite game to
          build the momentum necessary to level up in life. "
        </p>

        <TypedText
          className="font-Press-Start mt-8 mx-auto text-left text-coral text-4xl leading-snug md:w-full"
          text="READY PLAYER ONE???"
        />
      </OnScreen>

      <div className="my-8 text-center">
        <h2 className="font-Orbitron mb-4 text-2xl md:text-3xl">
          Sign Up For the Momentum Blog
        </h2>

        <p>For weekly tips to gamify your habits to level up your life.</p>
      </div>

      <form
        action="https://mooremomentum.us20.list-manage.com/subscribe/post"
        method="POST"
      >
        <input type="hidden" name="u" value="705d046da14d7bc6e462e6ec4" />
        <input type="hidden" name="id" value="fc1be8fe04" />
        {/* This hidden input is the blog tag */}
        <input type="hidden" name="tags" value="2083449" />
        {/* Magic hidden input for blog tag */}
        <div className="mb-8 space-y-8">
          <div className="gap-8 grid sm:grid-cols-2">
            <div>
              <input
                className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md text-black w-full focus:border-blue"
                id="MERGE1"
                name="MERGE1"
                placeholder="First Name"
                required
                type="text"
              />
            </div>

            <div>
              <input
                className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md text-black w-full focus:border-blue"
                id="MERGE2"
                name="MERGE2"
                placeholder="Last Name"
                required
                type="text"
              />
            </div>
          </div>

          <div>
            <input
              className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md text-black w-full focus:border-blue"
              id="MERGE0"
              name="MERGE0"
              placeholder="Email Address"
              required
              type="email"
            />
          </div>
        </div>

        <div className="text-center">
          <Button className="px-12" type="submit">
            Subscribe
          </Button>
        </div>
      </form>
    </div>

    <div className="bg-gray py-16">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="font-Orbitron mb-8 text-2xl md:text-3xl">
          Have a Question? Submit it here.
        </h2>

        <form
          name="contact"
          method="POST"
          data-netlify="true"
          netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="contact" />
          <input type="hidden" name="bot-field" />

          <div className="space-y-8">
            <div className="gap-8 grid sm:grid-cols-2">
              <div>
                <input
                  className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md w-full focus:border-blue"
                  name="first-name"
                  placeholder="First Name"
                  required
                  type="text"
                />
              </div>

              <div>
                <input
                  className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md w-full focus:border-blue"
                  name="last-name"
                  placeholder="Last Name"
                  required
                  type="text"
                />
              </div>
            </div>

            <div className="gap-8 grid sm:grid-cols-2">
              <div>
                <input
                  className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md w-full focus:border-blue"
                  name="email-address"
                  placeholder="Email Address"
                  required
                  type="email"
                />
              </div>

              <div>
                <input
                  className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md w-full focus:border-blue"
                  name="phone-number"
                  placeholder="Phone Number"
                  required
                  type="tel"
                />
              </div>
            </div>

            <div>
              <textarea
                className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md w-full focus:border-blue"
                name="message"
                placeholder="Provide your message here..."
                rows={8}
              />
            </div>

            <Button className="px-12" type="submit">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>
  </Layout>
)

export default Contact
